<template>
  <div class="assets">
    <h5header :name="$t('asset.desc84')" :right="false" />
    <div class="assets-con">
      <div class="con-title">
        <span @click="$router.go(-1)">{{ $t("home.desc2") }}</span>
        <img src="../../public/images/new/righticon1.png" alt="" /><span>{{
          $t("asset.desc84")
        }}</span>
      </div>
      <div class="con-top flex animate__animated animate__fadeInUp">
        <div class="top-left">
          <div class="left-name">
            {{ $t("asset.desc85", { n1: info.coinName, n2: typeTitle }) }}
          </div>
          <div class="left-title flex">
            <img src="../../public/images/new/assetsicon.png" alt="" />{{
              $t("new.desc6")
            }}
          </div>
          <div class="left-price">${{ common.cutXiaoNum1(info.balance) }}</div>
        </div>
        <img :src="info.coinIcon" alt="" class="logo" />
      </div>
      <div class="record-btn flex">
        <div
          class="flexcenter btn1"
          v-if="info.rechargeStatus"
          @click="$router.push('/recharge?id=' + $route.query.id)"
        >
          {{ $t("asset.desc2") }}
        </div>
        <div
          class="flexcenter"
          v-if="info.buyPlanetStatus"
          @click="$router.push('/node')"
        >
          {{ $t("asset.desc79") }}
        </div>
      </div>
      <div
        class="record-title animate__animated animate__fadeInUp animate__delay-.2s"
      >
        {{ $t("asset.desc86") }}
      </div>
      <div
        class="record-select flex animate__animated animate__fadeInUp animate__delay-.4s"
      >
        <div class="select-time flex">
          <el-date-picker
            @change="dateSelect"
            v-model="value1"
            type="daterange"
            :range-separator="$t('record.desc2')"
            :start-placeholder="$t('record.desc1')"
            :end-placeholder="$t('record.desc3')"
          >
          </el-date-picker>
          <el-select v-model="type" :placeholder="$t('planet.desc1')">
            <el-option :value="0" :label="$t('record.desc14')"></el-option>
            <el-option :value="'-1'" :label="$t('asset.desc89')"></el-option>
            <el-option :value="1" :label="$t('asset.desc90')"></el-option>
            <el-option :value="8" :label="$t('asset.desc223')"></el-option>
            <el-option :value="214" :label="$t('asset.desc99')"></el-option>
            <el-option :value="220" :label="$t('market.desc137')"></el-option>
            <el-option :value="219" :label="$t('market.desc138')"></el-option>
            <el-option :value="218" :label="$t('market.desc139')"></el-option>
            <el-option :value="217" :label="$t('market.desc140')"></el-option>
            <el-option :value="215" :label="$t('market.desc141')"></el-option>
            <el-option :value="216" :label="$t('market.desc160')"></el-option>
            <el-option :value="200" :label="$t('market.desc142')"></el-option>
            <el-option :value="221" :label="$t('market.desc147')"></el-option>
            <el-option :value="222" :label="$t('market.desc150')"></el-option>
            <el-option :value="223" :label="$t('market.desc151')"></el-option>
            <el-option :value="224" :label="$t('market.desc152')"></el-option>
            <el-option :value="225" :label="$t('market.desc153')"></el-option>
            <el-option :value="226" :label="$t('market.desc154')"></el-option>
            <el-option :value="227" :label="$t('market.desc155')"></el-option>
            <el-option :value="228" :label="$t('market.desc156')"></el-option>
            <el-option :value="229" :label="$t('market.desc157')"></el-option>
            <el-option :value="230" :label="$t('market.desc158')"></el-option>
            <el-option :value="231" :label="$t('market.desc159')"></el-option>
            <el-option :value="240" :label="$t('market.desc161')"></el-option>
            <el-option :value="300" :label="$t('market.desc162')"></el-option>
            <el-option :value="301" :label="$t('market.desc163')"></el-option>
            <el-option :value="302" :label="$t('market.desc165')"></el-option>
            <el-option :value="303" :label="$t('market.desc164')"></el-option>
            <el-option :value="701" :label="$t('market.desc166')"></el-option>
            <el-option :value="702" :label="$t('market.desc167')"></el-option>
            <el-option :value="710" :label="$t('market.desc168')"></el-option>
            <el-option :value="711" :label="$t('market.desc169')"></el-option>
            <el-option :value="704" :label="$t('market.desc170')"></el-option>
            <el-option :value="705" :label="$t('market.desc171')"></el-option>
            <el-option :value="40" :label="$t('market.desc207')+'DU'"></el-option>
            <el-option :value="41" :label="$t('market.desc219')"></el-option>
            <el-option :value="42" :label="$t('market.desc207')+'RC'"></el-option>
            <el-option :value="755" :label="$t('market.desc222')"></el-option>
          </el-select>
        </div>
        <div class="select-btn flex">
          <div class="flexcenter" @click="handlerReset">
            {{ $t("asset.desc87") }}
          </div>
          <div class="flexcenter" @click="handlerSearch">
            {{ $t("asset.desc88") }}
          </div>
        </div>
      </div>
      <div
        class="record-table animate__animated animate__fadeInUp animate__delay-.6s"
      >
        <div class="table-thead flex">
          <div>{{ $t("record.desc5") }}</div>
          <div>{{ $t("record.desc8") }}</div>

          <div>{{ $t("quotes.desc17") }}</div>

          <div>{{ $t("market.desc25") }}</div>
          <div>{{ $t("record.desc12") }}</div>
        </div>
        <div class="table-tbody">
          <div class="tbody-list flex" v-for="item in list" :key="item.id">
            <div>{{ item.date }}</div>
            <div>{{ item.type }}</div>

            <div
              :style="{
                color: parseFloat(item.amount) > 0 ? '#C9FA5B' : '#EB4343',
              }"
            >
              <span v-if="parseFloat(item.amount) > 0">+</span>
              {{ common.cutXiaoNum1(item.amount) }} {{ item.coinName }}
            </div>
            <div>{{ common.cutXiaoNum1(item.balance) || 0 }} {{ item.coinName }}</div>
            <div>{{ item.status }}</div>
          </div>
        </div>
        <div class="no-data" v-if="showNull">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
      </div>
      <div class="rr-h5-time-out animate__animated animate__bounceInUp">
        <div class="rr-h5-time">
          <img src="../../public/images/asset/time-icon.png" />
          <div class="item" @click="startShow = true">
            <input
              type="text"
              v-model="startTime"
              :placeholder="$t('record.desc1')"
              disabled
            />
          </div>
          <span>{{ $t("record.desc2") }}</span>
          <div class="item" @click="endShow = true">
            <input
              type="text"
              v-model="endTime"
              :placeholder="$t('record.desc3')"
              disabled
            />
          </div>
        </div>
        <div class="rr-h5-select">
          <el-select
            v-model="type"
            :placeholder="$t('planet.desc1')"
            @change="onChage"
          >
            <el-option :value="0" :label="$t('record.desc14')"></el-option>
            <el-option :value="'-1'" :label="$t('asset.desc89')"></el-option>
            <el-option :value="1" :label="$t('asset.desc90')"></el-option>
            <el-option :value="8" :label="$t('asset.desc223')"></el-option>
            <el-option :value="214" :label="$t('asset.desc99')"></el-option>
            <el-option :value="220" :label="$t('market.desc137')"></el-option>
            <el-option :value="219" :label="$t('market.desc138')"></el-option>
            <el-option :value="218" :label="$t('market.desc139')"></el-option>
            <el-option :value="217" :label="$t('market.desc140')"></el-option>
            <el-option :value="215" :label="$t('market.desc141')"></el-option>
            <el-option :value="200" :label="$t('market.desc142')"></el-option>
            <el-option :value="221" :label="$t('market.desc147')"></el-option>
            <el-option :value="222" :label="$t('market.desc150')"></el-option>
            <el-option :value="223" :label="$t('market.desc151')"></el-option>
            <el-option :value="224" :label="$t('market.desc152')"></el-option>
            <el-option :value="225" :label="$t('market.desc153')"></el-option>
            <el-option :value="226" :label="$t('market.desc154')"></el-option>
            <el-option :value="227" :label="$t('market.desc155')"></el-option>
            <el-option :value="228" :label="$t('market.desc156')"></el-option>
            <el-option :value="229" :label="$t('market.desc157')"></el-option>
            <el-option :value="230" :label="$t('market.desc158')"></el-option>
            <el-option :value="231" :label="$t('market.desc159')"></el-option>
            <el-option :value="240" :label="$t('market.desc161')"></el-option>
            <el-option :value="300" :label="$t('market.desc162')"></el-option>
            <el-option :value="301" :label="$t('market.desc163')"></el-option>
            <el-option :value="302" :label="$t('market.desc165')"></el-option>
            <el-option :value="303" :label="$t('market.desc164')"></el-option>
            <el-option :value="701" :label="$t('market.desc166')"></el-option>
            <el-option :value="702" :label="$t('market.desc167')"></el-option>
            <el-option :value="710" :label="$t('market.desc168')"></el-option>
            <el-option :value="711" :label="$t('market.desc169')"></el-option>
            <el-option :value="704" :label="$t('market.desc170')"></el-option>
            <el-option :value="705" :label="$t('market.desc171')"></el-option>
            <el-option :value="40" :label="$t('market.desc207')+'DU'"></el-option>
            <el-option :value="41" :label="$t('market.desc219')"></el-option>
            <el-option :value="42" :label="$t('market.desc207')+'RC'"></el-option>
            <el-option :value="755" :label="$t('market.desc222')"></el-option>
          </el-select>
        </div>
      </div>
      <div
        class="planet-page animate__animated animate__fadeInUp animate__delay-1s"
      >
        <el-pagination
          layout="prev, pager, next"
          :pager-count="5"
          :page-size="10"
          :current-page="page"
          :total="totals"
          :hide-on-single-page="true"
          @current-change="handlerCurrent"
        >
        </el-pagination>
      </div>
      <div class="h5-list">
        <van-list
          v-model:loading="loading"
          :loading-text="$t('quotes.desc20')"
          :finished="finished"
          @load="onLoad"
        >
          <van-cell v-for="item in list" :key="item.id">
            <div class="h5list-con">
              <div class="con-name flex">
                <div>{{ item.type }}</div>
                <p
                  :style="{
                    color: parseFloat(item.amount) > 0 ? '#C9FA5B' : '#EB4343',
                  }"
                >
                  <span v-if="parseFloat(item.amount) > 0">+</span>
                  {{ common.cutXiaoNum1(item.amount) }} {{ item.coinName }}
                </p>
              </div>
              <div class="con-time flex">
                <div>{{ item.date }}</div>
                <p>{{ $t("market.desc25") }} {{ common.cutXiaoNum1(item.balance) }}</p>
              </div>
              <div class="con-time">{{ item.status }}</div>
            </div>
          </van-cell>
        </van-list>
        <div class="no-data" v-if="showNull">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <el-dialog v-model="showInfo">
      <div class="modal-info">
        <div class="info-title">
          {{ $t("asset.desc2") }} - {{ modalInfo.coinName }}
          <img
            src="../../public/images/new/closeicon.png"
            alt=""
            @click="showInfo = false"
          />
        </div>
        <div class="info-icon">
          <img src="../../public/images/new/success.png" alt="" />
          <div>{{ $t("asset.desc91") }}</div>
        </div>
        <div class="info-list">
          <div class="list-item flex">
            <div>{{ $t("asset.desc92") }}</div>
            <p>{{ modalInfo.tokenType }}</p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("asset.desc93") }}</div>
            <p class="flex">
              {{ modalInfo.fromAddress }}
              <img
                src="../../public/images/new/copy1.png"
                alt=""
                @click="copy($event, modalInfo.fromAddress)"
              />
            </p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("asset.desc94") }}</div>
            <p>{{ modalInfo.amount }} {{ modalInfo.coinName }}</p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("asset.desc95") }}</div>
            <p style="color: #c9fa5b">
              {{ modalInfo.relationCoinAmount }}
              {{ modalInfo.relationCoinName }}
            </p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("asset.desc96") }}</div>
            <p>{{ modalInfo.toAddress }}</p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("asset.desc97") }}</div>
            <p>{{ modalInfo.date }}</p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("asset.desc98") }}</div>
            <p class="flex">
              {{ modalInfo.txHash }}
              <img
                src="../../public/images/new/copy1.png"
                alt=""
                @click="copy($event, modalInfo.txHash)"
              />
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="showInfo1">
      <div class="modal-info">
        <div class="info-title">
          {{ $t("manage.desc42") }}
          <img
            src="../../public/images/new/closeicon.png"
            alt=""
            @click="showInfo1 = false"
          />
        </div>
        <div class="info-icon">
          <img src="../../public/images/new/success.png" alt="" />
          <div>{{ $t("asset.desc91") }}</div>
        </div>
        <div class="info-list">
          <div class="list-item flex">
            <div>{{ $t("record.desc11") }}</div>
            <p class="flex">
              {{ modalInfo.sn }}
              <img
                src="../../public/images/new/copy1.png"
                alt=""
                @click="copy($event, modalInfo.sn)"
              />
            </p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("record.desc19") }}</div>
            <p style="color: #eb4343">
              {{ modalInfo.paymentAmount }} {{ modalInfo.paymentCoinName }}
            </p>
          </div>
          <div class="list-item flex">
            <div>{{ $t("record.desc23") }}</div>
            <p>{{ modalInfo.data }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
    <van-popup
      v-model:show="startShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="startCurrentTime"
        :confirm-button-text="$t('home.desc20')"
        :cancel-button-text="$t('home.desc45')"
        type="date"
        @confirm="confirmStartFn()"
        @cancel="startShow = false"
      />
    </van-popup>
    <van-popup
      v-model:show="endShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="endCurrentTime"
        type="date"
        :confirm-button-text="$t('home.desc20')"
        :cancel-button-text="$t('home.desc45')"
        @confirm="confirmEndFn()"
        @cancel="endShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
import clipboard from "@/utils/libs/clipboard";
export default {
  components: {
    h5header,
  },
  data() {
    return {
      startCurrentTime: new Date(),
      endCurrentTime: new Date(),
      startShow: false,
      endShow: false,
      info: {},
      value1: "",
      type: 0,
      startTime: "",
      endTime: "",
      page: 1,
      list: [],
      showNull: false,
      totals: 0,
      showInfo: false,
      modalInfo: {},
      loading: false,
      finished: false,
      showInfo1: false,
      typeTitle: "",
    };
  },
  mounted() {
    const { id, act } = this.$route.query;

    if (act == 1) {
      this.typeTitle = this.$t("asset.desc100");
    } else if (act == 2) {
      this.typeTitle = this.$t("asset.desc101");
    } else {
      this.typeTitle = this.$t("new.desc9");
    }
    this.$post(this.URL.assets.view, {
      relationCoinId: Number(id),
      accountType: Number(act),
    }).then((res) => {
      if (res.code == 0) {
        this.info = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
    this.getlist();
  },
  methods: {
    onLoad() {
      let filter='',types=[];
      if(this.type == 0|| this.type == '-1' || this.type ==1){
        filter=this.type
      }else{
        types=[this.type]
      }
      setTimeout(() => {
        this.$post(this.URL.assets.record, {
          coinId: this.$route.query.id,
          types: types,
          filter:filter,
          startTime: this.startTime,
          endTime: this.endTime,
          page: this.page,
          pageSize: 10,
          accountType: this.$route.query.act,
        }).then((res) => {
          if (res.code == 0) {
            this.showNull = false;
            if (this.page == 1) {
              this.list = res.data.list;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.page++;
            this.loading = false;
            if (this.list.length == 0) {
              this.showNull = true;
            }
            if (this.list.length >= parseFloat(res.data.page.count)) {
              this.finished = true;
            }
          } else {
            this.$message.error(res.message);
          }
        });
      }, 500);
    },
    onChage(val) {
      this.page = 1;
      this.loading = true;
      this.finished = false;
      this.list = [];
      this.showNull = false;
      this.onLoad();
    },
    confirmStartFn() {
      this.startTime = this.newDate(this.startCurrentTime);
      this.startShow = false;
      if (this.endTime) {
        this.page = 1;
        this.loading = true;
        this.finished = false;
        this.list = [];
        this.showNull = false;
        this.onLoad();
      }
    },
    confirmEndFn() {
      this.endTime = this.newDate(this.endCurrentTime);
      this.endShow = false;
      if (this.startTime) {
        this.page = 1;
        this.loading = true;
        this.finished = false;
        this.list = [];
        this.onLoad();
      }
    },
    handlerInfo(info) {
      this.$post(this.URL.assets.detail, {
        recordId: info.id,
        date: info.date,
      }).then((res) => {
        if (res.code == 0) {
          this.modalInfo = res.data;
          if (info.typeCode == 200) {
            this.showInfo1 = true;
          } else {
            this.showInfo = true;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerSearch() {
      this.page = 1;
      this.totals = 0;
      this.showNull = false;
      this.getlist();
    },
    handlerReset() {
      this.page = 1;
      this.value1 = "";
      this.startTime = "";
      this.endTime = "";
      this.type = 0;
      this.totals = 0;
      this.showNull = false;
      this.getlist();
    },
    handlerCurrent(val) {
      this.page = val;
      this.getlist();
    },
    getlist() {
      let filter='',types=[];
      if(this.type == 0|| this.type == '-1' || this.type ==1){
        filter=this.type
      }else{
        types=[this.type]
      }
      this.$post(this.URL.assets.record, {
        coinId: this.$route.query.id,
        filter: filter,
        types:types,
        startTime: this.startTime,
        endTime: this.endTime,
        page: this.page,
        pageSize: 10,
        accountType: this.$route.query.act,
      }).then((res) => {
        if (res.code == 0) {
          this.list = res.data.list;
          this.totals = parseFloat(res.data.page.count);
          if (this.list.length == 0) {
            this.showNull = true;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dateSelect(arr) {
      if (arr != null) {
        this.startTime = this.newDate(arr[0]);
        this.endTime = this.newDate(arr[1]);
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    newDate(time) {
      // 根据时间查询
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    copy(e, text) {
      clipboard(e, text);
      this.$message.success(this.$t("planet.desc61"));
    },
  },
};
</script>

<style lang="less" scoped>
.assets {
  width: 100%;
  padding: 136px 0 80px;
  background: url("../../public/images/new/landbg.png") center no-repeat;
  background-size: 100% 100%;

  .assets-con {
    width: 1240px;
    margin: 0 auto;

    .con-title {
      display: flex;
      margin-bottom: 20px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      img {
        width: 12px;
        height: 14px;
        margin: 0 8px;
      }
      span:nth-child(1) {
        cursor: pointer;
      }
      span:nth-child(3) {
        color: #fff;
      }
    }

    .con-top {
      padding: 40px;
      border-radius: 16px;
      background: #181818;

      .top-left {
        flex: 1;

        .left-name {
          font-size: 24px;
          color: #ffffff;
        }

        .left-title {
          margin: 32px 0 24px;
          font-size: 20px;
          color: #fff;
          line-height: 20px;

          img {
            width: 14px;
            height: 14px;
            margin: 3px 4px 0 0;
          }
        }

        .left-price {
          font-size: 44px;
          color: #c9fa5b;
        }
      }

      .logo {
        flex: 0 0 120px;
        width: 120px;
        height: 120px;
        margin: 12px 0 0 20px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .record-btn {
      display: none;
    }

    .record-title {
      margin: 40px 0 20px;
      font-size: 20px;
      color: #ffffff;
      line-height: 20px;
    }

    .record-select {
      margin-bottom: 24px;
      justify-content: space-between;

      .select-time {
        flex: 0 0 500px;
        ::v-deep .el-date-editor {
          height: 40px;
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
          background-color: rgba(255, 255, 255, 0);
          border: 1px solid rgba(255, 255, 255, 0.2) !important;
          .el-range-input {
            color: #fff;
          }

          .el-range-separator {
            color: #fff;
          }
        }

        ::v-deep .el-select {
          width: 300px;
          margin-left: 20px;

          .el-input__wrapper {
            background-color: rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
            height: 40px;

            .el-input__inner {
              color: #fff;
            }
          }

          .is-focus {
            box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
          }
        }
      }

      .select-btn {
        flex: 0 0 186px;
        justify-content: space-between;

        div {
          flex: 0 0 88px;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          font-size: 16px;
          color: #c9fa5b;
          cursor: pointer;

          &:last-child {
            background: #c9fa5b;
            color: #000;
            border: 1px solid #c9fa5b;
          }
        }
      }
    }

    .record-table {
      margin-top: 24px;

      .table-thead {
        padding: 20px 24px;
        background: #181818;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);

        div {
          flex: 1;

          &:last-child {
            flex: 0 0 80px;
            text-align: right;
          }
        }
      }

      .table-tbody {
        .tbody-list {
          padding: 22px 24px;
          font-size: 13px;
          color: #bbbbbb;
          border-bottom: 1px solid #0e0e0e;

          &:hover {
            background: #181818;
          }

          div {
            flex: 1;

            &:last-child {
              flex: 0 0 80px;
              cursor: pointer;
              text-align: right;
              img {
                width: 10px;
                height: 12px;
                margin: 3px 0 0 4px;
              }
            }
          }
        }
      }

      .no-data {
        width: 100%;
        padding: 130px 0;

        img {
          display: block;
          width: 120px;
          height: 120px;
          margin: 0 auto 16px;
        }

        span {
          display: block;
          margin: 0 auto;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #a1a1a1;
        }
      }
    }
  }

  .h5-list {
    display: none;
  }

  .planet-page {
    padding: 0;
    margin-top: 40px;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #c9fa5b !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }

  .rr-h5-time-out {
    display: none;
  }
}

::v-deep .el-dialog {
  width: 520px;

  .modal-info {
    padding: 32px;

    .info-title {
      position: relative;
      font-size: 20px;
      color: #fff;

      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .info-icon {
      margin: 32px 0;
      text-align: center;
      font-size: 24px;
      color: #ffffff;

      img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }
    }

    .info-list {
      padding: 20px;
      border-radius: 8px;
      background: #242424;

      .list-item {
        margin-bottom: 16px;
        font-size: 16px;

        &:nth-child(6) {
          padding-top: 16px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }

        div {
          flex: 0 0 30%;

          color: rgba(255, 255, 255, 0.6);
        }

        p {
          flex: 0 0 70%;
          text-align: right;
          color: #fff;
          justify-content: flex-end;
          img {
            width: 12px;
            height: 12px;
            margin: 4px 0 0 8px;
            cursor: pointer;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .assets {
    padding: 88px 0;

    .assets-con {
      width: calc(100vw - 8px);

      .con-top {
        padding: 20px;

        .top-left {
          .left-name {
            font-size: 20px;
          }

          .left-title {
            font-size: 16px;
          }

          .left-price {
            font-size: 32px;
          }
        }
      }

      .record-select {
        // flex-wrap: wrap;
        .select-btn{
          // margin-top: 16px;
        }
        .select-time {
          flex: 0 0 540px;

          ::v-deep .el-date-editor {
            width: 260px;
         
          }
          ::v-deep .el-select{
            width: 260px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .assets {
    padding: 56px 0;

    .assets-con {
      .con-title {
        display: none;
      }

      .con-top {
        padding: 0;
        background: none;

        .top-left {
          flex: 0 0 100%;

          .left-name {
            display: none;
          }

          .left-title {
            font-size: 14px;
            margin: 0 0 16px 0;
          }

          .left-price {
            font-size: 24px;
          }
        }

        .logo {
          display: none;
        }
      }

      .record-title {
        margin: 24px 0 16px;
        font-size: 16px;
      }

      .record-select {
        display: none;
      }

      .record-btn {
        margin: 24px 0 0;
        display: flex;
        justify-content: space-between;

        div {
          flex: 0 0 48%;
          height: 36px;

          font-size: 16px;
          background: url("../../public/images/new/btnbg17.png") center
            no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }

        .btn1 {
          background: url("../../public/images/new/btnbg16.png") center
            no-repeat;
          background-size: 100% 100%;
          color: #000000;
        }
      }

      .record-table {
        display: none;
      }
    }

    .rr-h5-time-out {
      display: block;
    }

    .rr-h5-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;
      height: 48px;
      padding: 0 44px 0 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;

      &-out {
        display: block;
        padding: 0;
        animation-delay: 0.3s;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 18px;
      }

      span {
        display: block;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;
        padding: 0 18px;
        width: 80px;
        text-align: center;
      }

      input {
        width: 100%;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;

        &::placeholder {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          text-transform: capitalize;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .rr-h5-select {
      ::v-deep .el-select {
        width: 100%;

        .el-input__wrapper {
          background-color: rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
          height: 40px;

          .el-input__inner {
            color: #fff;
          }
        }

        .is-focus {
          box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
        }
      }
    }

    .h5-list {
      margin-top: 16px;
      display: block;
      padding: 0 14px;
      border-radius: 8px;
      background: #181818;

      ::v-deep .van-list {
        .van-cell {
          padding: 15px 0;
          background: none;
          &::after {
            left: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.06);
          }
        }
        .con-name {
          margin-bottom: 8px;
          font-size: 16px;
          color: #ffffff;
          div {
            flex: 0 0 40%;
          }
          p {
            flex: 0 0 60%;
            text-align: right;
          }
        }
        .con-time {
          font-size: 12px;
          color: #bbbbbb;
          div {
            flex: 0 0 40%;
          }
          p {
            flex: 0 0 60%;
            text-align: right;
          }
        }
      }
      .no-data {
        width: 100%;
        padding: 40px 0;

        img {
          display: block;
          width: 120px;
          height: 120px;
          margin: 0 auto 16px;
        }

        span {
          display: block;
          margin: 0 auto;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #a1a1a1;
        }
      }
    }

    .planet-page {
      display: none;
    }
  }
  ::v-deep .el-dialog {
    .modal-info {
      padding: 16px;
      .info-icon {
        margin: 16px 0;
        font-size: 16px;
      }
      .info-list {
        padding: 12px;
        .list-item {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
